import { gql } from '@apollo/client'

export default gql`
  mutation deleteAdUnit($id: ID!) {
    deleteAdUnit(id: $id) {
      data {
        id
      }
    }
  }
`
