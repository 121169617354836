import { gql } from '@apollo/client'

export default gql`
  mutation createProperty(
    $name: String!
    $url: String!
    $gam_id: Long
    $date: DateTime
  ) {
    createProperty(
      data: { name: $name, url: $url, publishedAt: $date, gam_id: $gam_id }
    ) {
      data {
        id
        __typename
        attributes {
          name
          url
        }
      }
    }
  }
`
