import { useMutation } from '@apollo/client'
import { DefaultButton, Panel, PrimaryButton, TextField } from '@fluentui/react'
import React, { useState } from 'react'
import CreatePropertyQuery from '../queries/CreatePropertyQuery'

const NewPropertyDialog = (props) => {
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const { onDismiss } = props
  const [createProperty, { data, loading, error, reset }] = useMutation(
    CreatePropertyQuery,
    { refetchQueries: ['getProperties'] }
  )
  if (data && !error && !loading) {
    reset()
    onDismiss()
    setName('')
    setUrl('')
  }
  return (
    <Panel
      headerText="Create Property"
      {...props}
      onRenderFooterContent={() => (
        <div>
          <PrimaryButton
            onClick={() => {
              createProperty({
                variables: {
                  name,
                  url,
                  date: new Date().toISOString(),
                },
              })
            }}
          >
            Create
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </div>
      )}
      isFooterAtBottom={true}
    >
      {!loading && !!error && (
        <div style={{ color: 'red' }}>{error.message}</div>
      )}
      <TextField
        onChange={(t) => setName(t.target.value)}
        label="Name"
        value={name}
      />
      <TextField
        onChange={(t) => setUrl(t.target.value)}
        label="Url"
        value={url}
      />
    </Panel>
  )
}

export default NewPropertyDialog
