import { gql } from '@apollo/client'

export default gql`
  mutation createPartner($name: String!, $date: DateTime!) {
    createPartner(data: { name: $name, publishedAt: $date }) {
      data {
        id
      }
    }
  }
`
