import React, { useRef } from 'react'
import {
  List,
  mergeStyleSets,
  getTheme,
  normalize,
  ContextualMenu,
} from '@fluentui/react'
import AdUnitItem from './AdUnitItem'

const theme = getTheme()
const styles = mergeStyleSets({
  container: {
    overflow: 'auto',
    maxHeight: 400,
    border: '1px solid #CCC',
    marginTop: 20,
    selectors: {
      '.ms-List-cell:nth-child(odd)': {
        height: 40,
        lineHeight: 40,
        background: theme.palette.neutralLighter,
      },
      '.ms-List-cell:nth-child(even)': {
        height: 40,
        lineHeight: 40,
      },
    },
  },
  itemContent: [
    theme.fonts.medium,
    normalize,
    {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'block',
      borderLeft: '3px solid ' + theme.palette.themePrimary,
      paddingLeft: 27,
      ':hover': {
        background: theme.palette.neutralTertiaryAlt,
      },
    },
  ],
})
const AdUnitView = ({ adunits, refetch, ...rest }) => {
  // console.log(adunits)
  return (
    <>
      <h3>AdUnits</h3>
      <div className={styles.container}>
        {/* {!!adunits && adunits.map((adUnit) => <>{adUnit.attributes.name}</>)} */}
        <List
          items={adunits}
          onRenderCell={(adunit, index) => (
            <AdUnitItem
              adunit={adunit}
              index={index}
              refetch={refetch}
              key={adunit.id}
              {...rest}
            />
          )}
        />
      </div>
    </>
  )
}

export default AdUnitView
