import { useMutation, useQuery } from '@apollo/client'
import {
  ComboBox,
  DefaultButton,
  Label,
  Position,
  SpinButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react'
import React, { useState } from 'react'
import GetPropertyDetails from '../queries/GetPropertyDetailsQuery'
import UpdateProperyDetailsQuery from '../queries/UpdateProperyDetailsQuery'
import AdUnitView from './AdUnitView'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'


const PropertyDetails = ({ property, platform }) => {
  const { data, refetch } = useQuery(GetPropertyDetails, {
    variables: {
      id: property.id,
      platform: platform,
    },
  })

  const { ad_units } = data?.property?.data?.attributes || {}
  const [gamId, setGamId] = useState(property.attributes.gam_id || '')
  const [amznId, setAmznId] = useState(property.attributes.amzn_pub_id || '')
  const [adSystem, setAdSystem] = useState(property.attributes.ad_system || '')
  const [freestarId, setFreestarId] = useState(property.attributes.freestar_id || '')
  const [oneTrustDataDomainScript, setOneTrustDataDomainScript] = useState(property.attributes.ot_data_domain_script || '')
  const [flippZoneId, setFlippZoneId] = useState(property.attributes.flipp_zone_id || '')
  const [blacklistUrls, setBlacklistUrls] = useState(
    property.attributes.blacklist_urls || ''
  )
  const [urlState, setUrlState] = useState(property.attributes.url)
  const [remoteConfig, setRemoteConfig] = useState(property?.attributes?.remote_config || {})

  const [updateProperty] = useMutation(UpdateProperyDetailsQuery)
  console.log(data, remoteConfig)
  return (
    <Stack>
      <h1>
        {property.attributes.name} {!!platform && ` - ${platform}`}
      </h1>
      <StackItem>
        <TextField
          label="URL"
          value={urlState}
          onChange={(e, v) => setUrlState(v)}
        />
      </StackItem>
      <StackItem>
        <ComboBox
          label="Ad System"
          selectedKey={adSystem}
          onChange={(e, item) => {
            setAdSystem(item.key)
          }}
          options={[
            { key: 'gam', text: 'Google Ad Manager' },
            { key: 'freestar', text: 'Freestar' },
          ]}
        />
      </StackItem>
      <StackItem>
        <SpinButton
          label="GAM Account ID"
          labelPosition={Position.top}
          value={gamId}
          onChange={(e, v) => setGamId(v)}
        />
      </StackItem>
      <StackItem>
        <TextField label="Freestar ID" value={freestarId} onChange={(e, v) => setFreestarId(v)} />
      </StackItem>
      <StackItem>
        <SpinButton
          label="Amazon Publisher ID"
          labelPosition={Position.top}
          value={amznId}
          onChange={(e, v) => setAmznId(v)}
        />
      </StackItem>
      <StackItem>
        <TextField
          label="OneTrust Data Domain Script"
          labelPosition={Position.top}
          value={oneTrustDataDomainScript}
          onChange={(e, v) => setOneTrustDataDomainScript(v)}
        />
      </StackItem>
      <StackItem>
        <TextField
          label="Flipp Zone ID"
          labelPosition={Position.top}
          value={flippZoneId}
          onChange={(e, v) => setFlippZoneId(v)}
        />
      </StackItem>
      <StackItem>
        <TextField
          label="Block Ads Urls (one URI regex pattern per line)"
          multiline
          value={blacklistUrls}
          onChange={(e, v) => setBlacklistUrls(v)}
        />
      </StackItem>



      <StackItem>
        <Label>Remote Config</Label>
        <JSONInput
          height="150px"
          placeholder={
            remoteConfig
          }
          locale={locale}
          waitAfterKeyPress={3000}
          onChange={(v) => {
            setRemoteConfig((p) => {
              p = v.jsObject

              return { ...p }
            })
          }}
        />

      </StackItem>
      <StackItem style={{ paddingTop: '10px' }}>
        <DefaultButton
          onClick={() =>
            updateProperty({
              variables: {
                id: property.id,
                gam_id: gamId,
                url: urlState,
                amzn_pub_id: amznId,
                blacklist_urls: blacklistUrls,
                ot_data_domain_script: oneTrustDataDomainScript,
                remote_config: remoteConfig,
                flipp_zone_id: flippZoneId,
                ad_system: adSystem,
                freestar_id: freestarId
              },
            })
          }
        >
          Save
        </DefaultButton>
      </StackItem>
      <StackItem>
        <AdUnitView
          refetch={refetch}
          adunits={ad_units?.data}
          property={property}
          platform={platform}
        />
      </StackItem>
    </Stack>
  )
}

export default PropertyDetails
