import { useMutation } from '@apollo/client'
import {
  Dropdown,
  IconButton,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react'
import React, { useState } from 'react'
import UpdatePartnerQuery from '../queries/UpdatePartnerQuery'

const dropdownStyles = {
  dropdown: { width: 100 },
}
const PartnerDetails = ({ partner }) => {
  const { name, param_fields } = partner.attributes
  const [updatePartner, { data }] = useMutation(UpdatePartnerQuery)
  const { id } = partner

  const [fieldsState, setFieldsState] = useState(
    param_fields ? [...param_fields] : [{ key: '', type: 'string' }]
  )
  return (
    <div>
      <h1>{name}</h1>
      <Stack horizontal>
        <StackItem align="center">
          <h3>Parameter Fields</h3>
        </StackItem>
        <StackItem align="center">
          <IconButton
            iconProps={{ iconName: 'Add' }}
            label="Add Field"
            onClick={() => {
              setFieldsState((oldState) => [
                ...oldState,
                { key: '', type: 'string' },
              ])
            }}
          />
        </StackItem>
      </Stack>

      {fieldsState.map((f, i) => {
        const updateField = (v) => {
          const newField = { ...f, ...v }
          setFieldsState((oldState) => {
            oldState[i] = newField
            return [...oldState]
          })
        }
        return (
          <Stack horizontal key={i} style={{ marginTop: '10px' }}>
            <StackItem>
              <TextField
                defaultValue={f.key}
                onChange={(e) => {
                  updateField({ key: e.target.value })
                }}
              />
            </StackItem>
            <StackItem style={{ marginLeft: '10px' }}>
              <Dropdown
                styles={dropdownStyles}
                options={[
                  { key: 'string', text: 'String' },
                  { key: 'integer', text: 'Integer' },
                  { key: 'float', text: 'Float' },
                  { key: 'json', text: 'JSON' },
                ]}
                defaultSelectedKey={f.type}
                onChange={(e, v) => {
                  updateField({ type: v.key })
                }}
              />
            </StackItem>
          </Stack>
        )
      })}
      <PrimaryButton
        style={{ marginTop: '10px' }}
        onClick={() => {
          const fields = fieldsState.filter((f) => !!f.key)
          updatePartner({
            variables: {
              id,
              name,
              paramFields: fields,
            },
          })
        }}
      >
        Save
      </PrimaryButton>
    </div>
  )
}

export default PartnerDetails
