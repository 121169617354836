const { gql } = require('@apollo/client')

export default gql`
  query getProperties {
    properties {
      data {
        id
        __typename
        attributes {
          name
          url
          gam_id
          amzn_pub_id
          blacklist_urls
          ot_data_domain_script
          remote_config
          flipp_zone_id
          ad_system
          freestar_id
        }
      }
    }
  }
`
