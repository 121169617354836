import { gql } from '@apollo/client'

export default gql`
  mutation updatePartner($name: String!, $id: ID!, $paramFields: JSON) {
    updatePartner(id: $id, data: { name: $name, param_fields: $paramFields }) {
      data {
        id
        __typename
        attributes {
          name
          param_fields
        }
      }
    }
  }
`
