import { gql } from '@apollo/client'

export default gql`
  query getAdUnitDetails($id: ID) {
    adUnit(id: $id) {
      data {
        id
        attributes {
          name
          platform
          refresh_seconds
          refresh_on_url_change
          no_fill_refresh_multiplier
          disabled
          lazy_load
          partner_adunits {
            data {
              id
              attributes {
                params
                partner {
                  data {
                    id
                    attributes {
                      name
                      param_fields
                    }
                  }
                }
              }
            }
          }
          adunit_sizes {
            data {
              id
              attributes {
                width
                height
                floor
              }
            }
          }
        }
      }
    }
    partners {
      data {
        id
        attributes {
          name
          param_fields
        }
      }
    }
  }
`
