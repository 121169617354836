import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { initializeIcons } from '@fluentui/font-icons-mdl2'

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  useQuery,
  from,
  gql,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import AuthorizationDialog from './components/AuthorizationDialog'
import { ThemeProvider } from '@fluentui/react'
initializeIcons()
// console.log('token', window.localStorage.getItem('auth_token'))
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: `Bearer ${window.localStorage.getItem('auth_token')}`,
      ...headers,
    },
  }))
  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  // console.log(graphQLErrors, networkError, response)
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      // toast.error(`Error: ${message}`)
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })

  if (networkError) {
    if (!window.localStorage.getItem('auth_token')) window.showAuthDialog()
    console.error(`[Network error]: ${networkError}`)
    console.error(networkError.statusCode)
    if (networkError.statusCode === 401) window.showAuthDialog()
    //toast.error('Network Error')
  }
  return null
})
const link = from([
  authLink,
  errorLink,
  new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER }),
])
// console.log(process.env)
const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      PropertyEntity: {
        fields: {
          attributes: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming)
            },
          },
        },
      },
    },
  }),
  link,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider>
    <ApolloProvider client={client}>
      <App />
      <AuthorizationDialog />
    </ApolloProvider>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
