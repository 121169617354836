import React, { useRef, useState } from 'react'
import {
  mergeStyleSets,
  getTheme,
  normalize,
  ContextualMenu,
  Stack,
  StackItem,
} from '@fluentui/react'
import { useMutation } from '@apollo/client'
import DeleteAdUnitQuery from '../queries/DeleteAdUnitQuery'
import AdUnitPanel from './AdUnitPanel'

const theme = getTheme()
const styles = mergeStyleSets({
  itemContent: [
    theme.fonts.medium,
    normalize,
    {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'block',
      borderLeft: '3px solid ' + theme.palette.themePrimary,
      paddingLeft: 27,
      cursor: 'pointer',
      ':hover': {
        background: theme.palette.neutralTertiaryAlt,
      },
    },
  ],
})
const AdUnitItem = ({ adunit, index, refetch, ...rest }) => {
  const ref = useRef()
  const [hidden, setHidden] = useState(true)
  const [deleteAdUnit, { loading, errror, data }] =
    useMutation(DeleteAdUnitQuery)
  const [editAdUnitOpen, setEditAdUnitOpen] = useState(false)
  return (
    <div data-is-focusable>
      <div
        ref={ref}
        className={styles.itemContent}
        onClick={() => setHidden(false)}
      >
        <Stack horizontal>
          <StackItem grow={5}>{adunit.attributes.name}</StackItem>
          <StackItem style={{ paddingLeft: '20px' }} grow={1}>
            {adunit.attributes.adunit_sizes.data
              .map((s) => `${s.attributes.width}x${s.attributes.height}`)
              .join(', ')}
          </StackItem>
        </Stack>
      </div>
      <ContextualMenu
        target={ref.current}
        hidden={hidden}
        onDismiss={() => setHidden(true)}
        items={[
          {
            key: 'edit',
            text: 'Edit',
            onClick: () => setEditAdUnitOpen(true),
          },
          {
            key: 'delete',
            text: 'Delete',
            onClick: function () {
              if (window.confirm('Are you sure you want to delete this ad unit?')) {
                deleteAdUnit({
                  variables: { id: adunit.id },
                  onCompleted: refetch,
                });
              }
            },
          }
        ]}
      />
      {editAdUnitOpen && (
        <AdUnitPanel
          adUnit={adunit}
          isOpen={editAdUnitOpen}
          onDismiss={() => setEditAdUnitOpen(false)}
          {...rest}
        />
      )}
    </div>
  )
}

export default AdUnitItem
