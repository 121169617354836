import { useMutation } from '@apollo/client'
import {
  IconButton,
  Position,
  SpinButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react'
import React, { useState } from 'react'
import DeleteAdUnitSizeQuery from '../queries/DeleteAdUnitSizeQuery'
import EditAdUnitSizeQuery from '../queries/EditAdUnitSizeQuery'

function AdUnitSize(props) {
  const { size } = props
  const [changed, setChanged] = useState(false)
  const [width, setWidth] = useState(size?.attributes.width || 0)
  const [height, setHeight] = useState(size?.attributes.height || 0)
  const [floor, setFloor] = useState(size?.attributes.floor || 0)
  const [updateSize] = useMutation(EditAdUnitSizeQuery, {
    variables: {
      id: size.id,
    },
    refetchQueries: ['getAdUnitDetails'],
  })
  const [deleteSize] = useMutation(DeleteAdUnitSizeQuery, {
    variables: {
      id: size.id,
    },
    refetchQueries: ['getAdUnitDetails'],
  })

  const styles = { spinButtonWrapper: { width: 75 } }
  return (
    <Stack horizontal tokens={{ childrenGap: 10 }}>
      <StackItem>
        <SpinButton
          labelPosition={Position.top}
          label="Width"
          value={width}
          styles={styles}
          onChange={(e, v) => {
            setChanged(true)

            setWidth(parseInt(v, 10))
          }}
        />
      </StackItem>
      <StackItem>
        <SpinButton
          labelPosition={Position.top}
          label="Height"
          value={height}
          styles={styles}
          onChange={(e, v) => {
            setChanged(true)
            setHeight(parseInt(v, 10))
          }}
        />
      </StackItem>
      <StackItem>
        <SpinButton
          labelPosition={Position.top}
          label="Floor"
          value={floor}
          styles={styles}
          onChange={(e, v) => {
            setChanged(true)
            setFloor(parseFloat(v))
          }}
        />
      </StackItem>

      {changed && (
        <StackItem align="end">
          <IconButton
            iconProps={{ iconName: 'Save' }}
            label="Save Size"
            onClick={() => {
              updateSize({
                variables: {
                  width,
                  height,
                  floor,
                },
              })
              setChanged(false)
            }}
          />
        </StackItem>
      )}
      <StackItem grow>&nbsp;</StackItem>
      <StackItem align="end">
        <IconButton
          iconProps={{ iconName: 'Delete' }}
          label="Delete Size"
          onClick={() => {
            deleteSize()
          }}
        />
      </StackItem>
    </Stack>
  )
}

export default AdUnitSize
