import { gql } from '@apollo/client'

export default gql`
  mutation updatePropertyDetails(
    $id: ID!
    $url: String
    $gam_id: Long
    $amzn_pub_id: Long
    $blacklist_urls: String
    $ot_data_domain_script: String
    $remote_config: JSON
    $flipp_zone_id: String
    $ad_system: ENUM_PROPERTY_AD_SYSTEM
    $freestar_id: String
  ) {
    updateProperty(
      id: $id
      data: { url: $url,
        gam_id: $gam_id, 
        amzn_pub_id: $amzn_pub_id, blacklist_urls: $blacklist_urls, ot_data_domain_script:$ot_data_domain_script, remote_config: $remote_config, flipp_zone_id: $flipp_zone_id, 
        ad_system: $ad_system 
        freestar_id: $freestar_id}
    ) {
      data {
        id
        attributes {
          name
          url
          gam_id
          amzn_pub_id
          blacklist_urls
          ot_data_domain_script
          remote_config
          flipp_zone_id
          ad_system
          freestar_id
        }
      }
    }
  }
`
