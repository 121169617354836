import { useMutation } from '@apollo/client'
import {
  IconButton,
  Label,
  Position,
  PrimaryButton,
  SpinButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react'

import React, { useState } from 'react'
import DeletePartnerAdunitQuery from '../queries/DeletePartnerAdunitQuery'
import UpdatePartnerAdunitParamsQuery from '../queries/UpdatePartnerAdunitParamsQuery'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'

const PartnerAdunitEdit = ({ partnerAdunit, partner }) => {
  // console.log('partner', partner, partnerAdunit)
  const paramFields = partner.attributes.param_fields

  const template = {}
  paramFields?.forEach((pf) => {
    if (pf.type === 'json') template[pf.key] = []
    else if (pf.type === 'integer' || pf.type === 'float') template[pf.key] = 0
    else template[pf.key] = ''
  })
  const [params, setParams] = useState(
    partnerAdunit.attributes.params
      ? { ...partnerAdunit.attributes.params }
      : template
  )
  const [changed, setChanged] = useState(false)
  const [updatePartner, { data }] = useMutation(
    UpdatePartnerAdunitParamsQuery,
    {
      refetchQueries: ['getAdUnitDetails'],
    }
  )
  const [removePartner] = useMutation(DeletePartnerAdunitQuery, {
    refetchQueries: ['getAdUnitDetails'],
  })
  return (
    <Stack style={{ border: '1px solid', marginTop: '20px', padding: '10px' }}>
      <StackItem>
        <Stack horizontal horizontalAlign="space-between">
          <StackItem align="center">
            <h3>{partner.attributes.name}</h3>
          </StackItem>
          <StackItem align="center">
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              label="Delete"
              onClick={() => {
                removePartner({
                  variables: {
                    id: partnerAdunit.id,
                  },
                })
              }}
            />
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem>
        <Stack>
          {paramFields?.map((field, idx) => {
            //console.log(field)
            return (
              <StackItem key={field.key}>
                {field.type === 'string' && (
                  <TextField
                    label={field.key}
                    value={params[field.key]}
                    onChange={(e, v) => {
                      setChanged(true)
                      setParams((p) => {
                        // console.log(p, e.target.value)
                        const value = e.target.value
                        if (field.type === 'string') p[field.key] = value
                        return { ...p }
                      })
                    }}
                  />
                )}
                {(field.type === 'integer' || field.type === 'float') && (
                  <SpinButton
                    labelPosition={Position.top}
                    label={field.key}
                    value={params[field.key]}
                    onChange={(e, v) => {
                      setChanged(true)
                      setParams((p) => {
                        if (field.type === 'integer')
                          p[field.key] = parseInt(v, 10)
                        if (field.type === 'float') p[field.key] = parseFloat(v)
                        return { ...p }
                      })
                    }}
                  />
                )}
                {field.type === 'json' && (
                  <>
                    <Label>{field.key}</Label>
                    <JSONInput
                      height="150px"
                      placeholder={
                        typeof params[field.key] == 'object'
                          ? params[field.key]
                          : {}
                      }
                      locale={locale}
                      waitAfterKeyPress={3000}
                      onChange={(v) => {
                        setChanged(true)
                        setParams((p) => {
                          p[field.key] = v.jsObject

                          return { ...p }
                        })
                      }}
                    />
                  </>
                )}
              </StackItem>
            )
          })}
        </Stack>
      </StackItem>
      {changed && (
        <StackItem style={{ marginTop: '10px' }}>
          <PrimaryButton
            onClick={() => {
              setChanged(false)
              updatePartner({
                variables: {
                  id: partnerAdunit.id,
                  params: params,
                },
              })
            }}
          >
            Save
          </PrimaryButton>
        </StackItem>
      )}
    </Stack>
  )
}

export default PartnerAdunitEdit
