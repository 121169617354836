import { gql } from '@apollo/client'

export default gql`
  mutation createAdUnit(
    $name: String!
    $platform: ENUM_ADUNIT_PLATFORM!
    $date: DateTime!
    $property: ID!
    $refresh_seconds: Int!
    $disabled: Boolean!
    $no_fill_refresh_multiplier: Float
    $lazy_load: Boolean
    $freestar_placement: String
  ) {
    createAdUnit(
      data: {
        name: $name
        platform: $platform
        publishedAt: $date
        property: $property
        refresh_seconds: $refresh_seconds
        disabled: $disabled
        no_fill_refresh_multiplier: $no_fill_refresh_multiplier
        lazy_load: $lazy_load
        freestar_placement: $freestar_placement
      }
    ) {
      data {
        id
      }
    }
  }
`
