import {
  CommandBar,
  Dialog,
  DialogContent,
  DialogFooter,
} from '@fluentui/react'
import React, { useState } from 'react'
import AdUnitDialog from './AdUnitPanel'
import CreatePartnerDialog from './CreatePartnerDialog'
import NewPropertyDialog from './NewPropertyDialog'
import PublishConfigDialog from './PublishConfigDialog'

const MenuBar = (props) => {
  const [addPropertyOpen, setAddPropertyOpen] = useState(false)
  const [addAdUnitOpen, setAddAdUnitOpen] = useState(false)
  const [addPartnerOpen, setAddPartnerOpen] = useState(false)
  const [publishOpen, setPublishOpen] = useState(false)
  const { property, platform } = props
  const newItems = [
    {
      key: 'property',
      text: 'Property',
      iconProps: { iconName: 'CityNext' },
      onClick: () => setAddPropertyOpen(true),
    },
    {
      key: 'partner',
      text: 'Partner',
      iconProps: { iconName: 'People' },
      onClick: () => setAddPartnerOpen(true),
    },
  ]
  if (property && platform) {
    newItems.push({
      key: 'adunit',
      text: 'AdUnit',
      iconProps: { iconName: 'Storyboard' },
      onClick: () => setAddAdUnitOpen(true),
    })
  }
  const items = [
    {
      key: 'newItem',
      text: 'New',
      iconProps: { iconName: 'Add' },
      subMenuProps: {
        items: newItems,
      },
    },
  ]
  if (property) {
    items.push({
      key: 'publish',
      text: 'Publish',
      iconProps: { iconName: 'Upload' },
      onClick: () => setPublishOpen(true),
    })
  }
  return (
    <div style={{ borderBottom: '1px solid #eee', width: '300px' }}>
      <CommandBar items={items} />
      <NewPropertyDialog
        isOpen={addPropertyOpen}
        onDismiss={() => setAddPropertyOpen(false)}
      />
      {addAdUnitOpen && (
        <AdUnitDialog
          platform={platform}
          property={property}
          isOpen={addAdUnitOpen}
          onDismiss={() => setAddAdUnitOpen(false)}
        />
      )}
      {addPartnerOpen && (
        <CreatePartnerDialog onClose={() => setAddPartnerOpen(false)} />
      )}
      {publishOpen && (
        <PublishConfigDialog
          onDismiss={() => setPublishOpen(false)}
          property={property}
        />
      )}
    </div>
  )
}
export default MenuBar
