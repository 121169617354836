import { gql } from '@apollo/client'

export default gql`
  query getPartners {
    partners {
      data {
        id
        attributes {
          name
          param_fields
        }
      }
    }
  }
`
