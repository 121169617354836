import { gql } from '@apollo/client'

export default gql`
  mutation updatePartnerAdunitParams($id: ID!, $params: JSON) {
    updatePartnerAdunit(id: $id, data: { params: $params }) {
      data {
        id
        __typename
        attributes {
          params
        }
      }
    }
  }
`
