import { gql } from '@apollo/client'

export default gql`
  mutation deleteParterAdunit($id: ID!) {
    deletePartnerAdunit(id: $id) {
      data {
        id
      }
    }
  }
`
