import { gql } from '@apollo/client'

export default gql`
  mutation updateAdUnit(
    $name: String!
    $id: ID!
    $refresh_seconds: Int
    $refresh_on_url_change: Boolean
    $disabled: Boolean
    $no_fill_refresh_multiplier: Float
    $lazy_load: Boolean
    $freestar_placement: String
  ) {
    updateAdUnit(
      id: $id
      data: {
        name: $name
        refresh_seconds: $refresh_seconds
        refresh_on_url_change: $refresh_on_url_change
        no_fill_refresh_multiplier: $no_fill_refresh_multiplier
        disabled: $disabled
        lazy_load: $lazy_load
        freestar_placement: $freestar_placement
      }
    ) {
      data {
        id
        __typename
        attributes {
          name
          platform
          refresh_seconds
          refresh_on_url_change
          no_fill_refresh_multiplier
          disabled
          lazy_load
          freestar_placement
        }
      }
    }
  }
`
