import {
  Dialog,
  DialogContent,
  DialogFooter,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import React, { useRef, useState } from "react";

function AuthorizationDialog({}) {
  const [hideDialog, setHideDialog] = useState(true);
  const passwordRef = useRef();

  const toggleHideDialog = () => setHideDialog((v) => !v);
  window.showAuthDialog = () => setHideDialog(false);

  return (
    <Dialog
      hidden={hideDialog}
      // onDismiss={toggleHideDialog}
      // dialogContentProps={dialogContentProps}
      // modalProps={modalProps}
      dialogContentProps={{
        title: "Enter Auth Code",
        closeButtonAriaLabel: "Close",
        subText: window.localStorage.getItem("auth_token")
          ? "Your auth token appears invalid. Please enter a valid token."
          : "Please enter your auth token",
      }}
    >
      <DialogContent>
        <TextField type="password" label="Auth Code" ref={passwordRef} />
      </DialogContent>
      <DialogFooter>
        <PrimaryButton
          text="Login"
          onClick={() => {
            const val = passwordRef.current.state.uncontrolledValue;
            window.localStorage.setItem("auth_token", val);
            window.location.reload();
          }}
        />
        {/* <DefaultButton onClick={toggleHideDialog} text="Don't send" /> */}
      </DialogFooter>
    </Dialog>
  );
}

export default AuthorizationDialog;
