import { gql } from '@apollo/client'

export default gql`
  mutation createAdunitSize(
    $adUnit: ID!
    $width: Int
    $height: Int
    $floor: Float
  ) {
    createSize(
      data: { ad_unit: $adUnit, floor: $floor, width: $width, height: $height }
    ) {
      data {
        id
        attributes {
          width
          height
          floor
          ad_unit {
            data {
              id
            }
          }
        }
      }
    }
  }
`
