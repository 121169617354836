import { gql } from '@apollo/client'

export default gql`
  query getPropertyDetails($id: ID, $platform: String!) {
    property(id: $id) {
      data {
        __typename
        id
        attributes {
          url
          name
          gam_id
          amzn_pub_id
          blacklist_urls
          ot_data_domain_script
          flipp_zone_id
          remote_config
          ad_system
          freestar_id
          ad_units(
            filters: { platform: { eq: $platform } }
            pagination: { limit: 100 }
            sort: ["id:asc"]
          ) {
            data {
              __typename
              id
              attributes {
                name
                refresh_seconds
                refresh_on_url_change
                no_fill_refresh_multiplier
                disabled
                lazy_load
                freestar_placement
                adunit_sizes {
                  data {
                    attributes {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
