import './App.css'
import PropertyNav from './components/PropertyNav'
import MenuBar from './components/MenuBar'
import { useState } from 'react'
import PropertyDetails from './components/PropertyDetails'
import PartnerDetails from './components/PartnerDetails'

const App = () => {
  const [property, _setProperty] = useState(null)
  const [platform, setPlatform] = useState(null)
  const [partner, _setPartner] = useState(null)
  const setPartner = (p) => {
    _setProperty(null)
    _setPartner(p)
  }
  const setProperty = (p) => {
    _setProperty(p)
    _setPartner(null)
  }

  return (
    <div className="App">
      <div style={{ display: 'flex' }}>
        <div>
          <h2>Mastodon Config</h2>
          <PropertyNav
            setProperty={setProperty}
            selectedProperty={property}
            platform={platform}
            setPlatform={setPlatform}
            partner={partner}
            setPartner={setPartner}
          />
        </div>
        <div style={{ paddingLeft: '10px' }}>
          <MenuBar property={property} platform={platform} />
          {!!property && !!platform && (
            <PropertyDetails
              property={property}
              platform={platform}
              key={property.id}
            />
          )}
          {!!partner && <PartnerDetails partner={partner} key={partner.id} />}
        </div>
      </div>
    </div>
  )
}

export default App
