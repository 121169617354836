import { useQuery } from '@apollo/client'
import { Nav } from '@fluentui/react'
import React from 'react'
import GetPartnersQuery from '../queries/GetPartnersQuery'
import GetPropertiesQuery from '../queries/GetPropertiesQuery'

const PropertyNav = ({
  setProperty,
  selectedProperty,
  platform,
  setPlatform,
  partner,
  setPartner,
}) => {
  const { data, loading, error } = useQuery(GetPropertiesQuery)
  const { data: pData } = useQuery(GetPartnersQuery)
  const properties = data?.properties?.data
  const partners = pData?.partners?.data
  return (
    <div>
      <Nav
        selectedKey={`${selectedProperty?.id}_${platform}`}
        groups={[
          {
            name: 'Properties',
            links: properties?.map((p) => {
              const { name } = p.attributes
              const { id } = p
              return {
                name: name,
                key: `${id}`,
                isExpanded: id === selectedProperty?.id,
                onClick: () => {
                  setProperty(p)
                },
                target: '_blank',
                links: [
                  {
                    name: 'Desktop',
                    onClick: () => setPlatform('desktop'),
                    key: `${id}_desktop`,
                  },
                  {
                    onClick: () => setPlatform('mobile'),
                    name: 'Mobile',
                    key: `${id}_mobile`,
                  },
                ],
              }
            }),
          },
          {
            name: 'Partners',
            links: partners?.map((partner) => {
              const { name } = partner.attributes
              const { id } = partner

              return {
                name,
                key: `${id}`,
                onClick: () => {
                  setPartner(partner)
                },
                target: '_blank',
              }
            }),
          },
        ]}
        styles={{
          root: {
            width: 208,
            height: '80vh',
            boxSizing: 'border-box',
            borderRight: '1px solid #eee',
            borderBottom: '1px solid #eee',
            overflowY: 'auto',
          },
        }}
      />
    </div>
  )
}

export default PropertyNav
