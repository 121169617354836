import { useMutation } from '@apollo/client'
import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  PrimaryButton,
  TextField,
} from '@fluentui/react'
import React, { useState } from 'react'
import CreatePartnerQuery from '../queries/CreatePartnerQuery'

function CreatePartnerDialog({ onClose }) {
  const [name, setName] = useState('')
  const [createPartner, { data, loading, error, reset }] = useMutation(
    CreatePartnerQuery,
    { refetchQueries: ['getPartners'] }
  )
  return (
    <Dialog
      dialogContentProps={{
        title: 'Create Partner',
        closeButtonAriaLabel: 'Close',
      }}
      onDismiss={() => onClose()}
      hidden={false}
    >
      <TextField label="Name" value={name} onChange={(_, v) => setName(v)} />
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            createPartner({
              variables: { name, date: new Date().toISOString() },
            })
            onClose()
          }}
        >
          Create Partner
        </PrimaryButton>
        <DefaultButton onClick={() => onClose()}>Cancel</DefaultButton>
      </DialogFooter>
    </Dialog>
  )
}

export default CreatePartnerDialog
