import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from '@fluentui/react'
import React, { useState } from 'react'
import useFetch from 'use-http'

const PublishConfigDialog = ({ onDismiss, property }) => {
  const { get, response, loading, error } = useFetch(
    `${process.env.REACT_APP_API_SERVER}`,
    {
      cachePolicy: 'no-cache',
      headers: {
        authorization: `Bearer ${window.localStorage.getItem('auth_token')}`,
      },
    }
  )
  const [env, setEnv] = useState()
  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{
        title: `Publish changes`,
      }}
    >
      {!loading && !response?.data && !error && (
        <>
          <div style={{ paddingBottom: '10px' }}>
            This will publish changes to {property.attributes.name}
          </div>
          <Dropdown
            label="Deploy to Environment"
            options={[
              { key: 'stage', text: 'Staging' },
              { key: 'prod', text: 'Production' },
            ]}
            selectedKey={env}
            onChange={(e, v) => setEnv(v.key)}
          />
          <DialogFooter>
            {!!env && (
              <PrimaryButton
                onClick={() =>
                  get(`/api/config/generate/${property.id}/${env}`)
                }
              >
                Publish
              </PrimaryButton>
            )}
            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </DialogFooter>
        </>
      )}
      <Stack horizontalAlign={'center'} verticalAlign={'center'}>
        {loading && <Spinner size={SpinnerSize.large} label="Publishing..." />}
        {!loading && !error && response.data?.result === 'ok' && (
          <>
            <StackItem>
              <Icon
                style={{ fontSize: '30px' }}
                iconName="Completed"
                label="Published"
              />
            </StackItem>
            <StackItem>
              <Label>Published</Label>
            </StackItem>
            <DialogFooter>
              <PrimaryButton onClick={onDismiss}>Done</PrimaryButton>
            </DialogFooter>
          </>
        )}
        {!loading && (error || response.data?.result === 'error') && (
          <>
            An error ocurred.
            <DialogFooter>
              <PrimaryButton onClick={onDismiss}>Done</PrimaryButton>
            </DialogFooter>
          </>
        )}
      </Stack>
    </Dialog>
  )
}

export default PublishConfigDialog
