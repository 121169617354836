import { gql } from '@apollo/client'

export default gql`
  mutation createParterAdunit($partner: ID, $adUnit: ID, $date: DateTime!) {
    createPartnerAdunit(
      data: { partner: $partner, ad_unit: $adUnit, publishedAt: $date }
    ) {
      data {
        id
        attributes {
          params
        }
      }
    }
  }
`
