import { gql } from '@apollo/client'

export default gql`
  mutation editAdunitSize(
    $id: ID!
    $width: Int!
    $height: Int!
    $floor: Float
  ) {
    updateSize(
      id: $id
      data: { floor: $floor, width: $width, height: $height }
    ) {
      data {
        id
        attributes {
          width
          height
          floor
        }
      }
    }
  }
`
