import { useMutation, useQuery } from '@apollo/client'
import {
  Checkbox,
  ComboBox,
  DefaultButton,
  IconButton,
  Panel,
  PanelType,
  Position,
  PrimaryButton,
  SpinButton,
  Stack,
  StackItem,
  TextField,
  TooltipHost,
} from '@fluentui/react'
import React, { useState } from 'react'
import CreateAdUnitQuery from '../queries/CreateAdUnitQuery'
import CreateAdUnitSizeQuery from '../queries/CreateAdUnitSizeQuery'
import CreatePartnerAdunitQuery from '../queries/CreatePartnerAdunitQuery'
import GetAdunitDetailsQuery from '../queries/GetAdunitDetailsQuery'
import UpdateAdUnitQuery from '../queries/UpdateAdUnitQuery'
import AdUnitSize from './AdUnitSize'
import PartnerAdunitEdit from './PartnerAdunitEdit'

const AdUnitPanel = (props) => {
  const { onDismiss, platform, property, adUnit } = props
  const [name, setName] = useState(adUnit ? adUnit.attributes.name ?? '' : '')
  const [freestarPlacement, setFreestarPlacement] = useState(adUnit ? adUnit.attributes.freestar_placement ?? "" : '')

  const [refreshOnUrlChange, setRefreshOnUrlChange] = useState(
    adUnit ? adUnit.attributes.refresh_on_url_change ?? false : false
  )
  const [disabled, setDisabled] = useState(
    adUnit ? adUnit.attributes.disabled ?? false : false
  )
  const [lazyLoad, setLazyLoad] = useState(
    adUnit ? adUnit.attributes.lazy_load ?? false : false
  )
  const [refresh, setRefresh] = useState(
    adUnit ? adUnit.attributes.refresh_seconds ?? 0 : 0
  )

  const [noFillRefreshMultiplier, setNoFillRefreshMultiplier] = useState(
    adUnit ? adUnit.attributes.no_fill_refresh_multiplier ?? 1.0 : 1.0
  )
  const [selectedPartner, setSelectedPartner] = useState()
  // const [selectedPartners, setSelectedPartners] = useState()
  const { data: details } = useQuery(GetAdunitDetailsQuery, {
    variables: { id: parseInt(adUnit?.id) || null },
    onCompleted: (data) => {
      //console.log('adUnitDetails   return', data)
      // setSelectedPartners(
      //   data?.adUnit?.data?.attributes?.partner_adunits?.data.map(
      //     (pa) => pa.id
      //   ) || []
      // )
    },
  })
  // console.log('selected', selectedPartners)
  const fullPartnerList = details?.partners?.data
  const [createAdUnit, { data, loading, error, reset }] = useMutation(
    CreateAdUnitQuery,
    { refetchQueries: ['getProperties'] }
  )
  const [
    updateAdUnit,
    {
      data: editData,
      loading: editLoading,
      error: editError,
      reset: editReset,
    },
  ] = useMutation(UpdateAdUnitQuery)
  const [createPartnerAdunit] = useMutation(CreatePartnerAdunitQuery, {
    refetchQueries: ['getAdUnitDetails'],
  })

  const [createAdUnitSize] = useMutation(CreateAdUnitSizeQuery, {
    refetchQueries: ['getAdUnitDetails'],
    variables: {
      adUnit: adUnit?.id,
    },
  })

  if (data && !error && !loading) {
    reset()
    onDismiss()
    if (!adUnit) {
      setName('')
    }
  }
  if (editData && !editError && !editLoading) {
    editReset()
    onDismiss()
    if (!adUnit) {
      setName('')
    }
  }

  const partner_adunits =
    details?.adUnit?.data?.attributes?.partner_adunits?.data
  //console.log(partner_adunits)
  return (
    <Panel
      type={PanelType.medium}
      headerText={adUnit ? 'Edit AdUnit' : 'Create AdUnit'}
      {...props}
      onRenderFooterContent={() => (
        <div>
          {!adUnit && (
            <PrimaryButton
              onClick={() => {
                createAdUnit({
                  variables: {
                    name,
                    property: property.id,
                    platform: platform,
                    date: new Date().toISOString(),
                    refresh_seconds: parseInt(refresh),
                    refresh_on_url_change: refreshOnUrlChange,
                    disabled: disabled,
                    lazy_load: lazyLoad,
                    no_fill_refresh_multiplier: parseFloat(
                      noFillRefreshMultiplier
                    ),
                    freestar_placement: freestarPlacement,

                  },
                })
              }}
            >
              Create
            </PrimaryButton>
          )}
          {!!adUnit && (
            <PrimaryButton
              onClick={() => {
                updateAdUnit({
                  variables: {
                    name,
                    id: adUnit.id,
                    refresh_seconds: parseInt(refresh),
                    refresh_on_url_change: refreshOnUrlChange,
                    disabled: disabled,
                    lazy_load: lazyLoad,
                    no_fill_refresh_multiplier: parseFloat(
                      noFillRefreshMultiplier
                    ),
                    freestar_placement: freestarPlacement,
                  },
                })
              }}
            >
              Save
            </PrimaryButton>
          )}

          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </div>
      )}
      isFooterAtBottom={true}
    >
      {!loading && !!error && (
        <div style={{ color: 'red' }}>{error.message}</div>
      )}
      {!editLoading && !!editError && (
        <div style={{ color: 'red' }}>{editError.message}</div>
      )}
      <TextField
        onChange={(t) => setName(t.target.value)}
        label="Name"
        value={name}
      />

      <SpinButton
        label="Refresh seconds (0 for no refresh)"
        labelPosition={Position.top}
        value={refresh}
        onChange={(e, v) => setRefresh(v)}
      />
      <TooltipHost
        content="Value to multiplier by refresh timer each time there is no fill"
        styles={{ root: { display: 'inline-block', width: '100%' } }}
      >
        <SpinButton
          label="No Fill Refresh Multiplier"
          labelPosition={Position.top}
          value={noFillRefreshMultiplier}
          onChange={(e, v) => setNoFillRefreshMultiplier(v)}
        />
      </TooltipHost>
      <TextField
        onChange={(t) => setFreestarPlacement(t.target.value)}
        label="Freestar Placement"
        value={freestarPlacement}
      />
      <TooltipHost
        content="Refresh the ad when the URL (page) changes"
        styles={{ root: { display: 'inline-block' } }}
      >
        <Checkbox
          label="Refresh on URL Change"
          checked={refreshOnUrlChange}
          onChange={(e, checked) => setRefreshOnUrlChange(!!checked)}
          styles={{
            root: {
              paddingTop: '10px',
            },
          }}
        />
      </TooltipHost>

      <TooltipHost
        content="Disable adunit from displaying"
        styles={{ root: { display: 'inline-block', paddingLeft: '15px' } }}
      >
        <Checkbox
          label="Disabled"
          checked={disabled}
          onChange={(e, checked) => setDisabled(!!checked)}
          styles={{
            root: {
              paddingTop: '10px',
            },
          }}
        />
      </TooltipHost>
      <TooltipHost
        content="Lazy load this adunit"
        styles={{ root: { display: 'inline-block', paddingLeft: '15px' } }}
      >
        <Checkbox
          label="Lazy Load"
          checked={lazyLoad}
          onChange={(e, checked) => setLazyLoad(!!checked)}
          styles={{
            root: {
              paddingTop: '10px',
            },
          }}
        />
      </TooltipHost>

      {/* <ComboBox
        label="Partners"
        multiSelect
        selectedKey={selectedPartners}
        options={fullPartnerList?.map((p) => {
          return { key: p.id, text: p.attributes.name }
        })}
        onChange={(e, item) => {
          console.log(item)
          setSelectedPartners((old) => {
            if (item.selected) setSelectedPartners((u) => [item.key, ...u])
            else setSelectedPartners((u) => u.filter((i) => i !== item.key))
          })
        }}
      /> */}
      {/* {selectedPartners?.map((id) => {
        const partner = fullPartnerList?.filter((d) => d.id === id)[0]
        console.log('partner', partner, id, fullPartnerList)
        return (
          <Stack>
            <h2>{partner?.attributes?.name}</h2>
          </Stack>
        )
      })} */}

      {!!details?.adUnit && (
        <div>
          <Stack horizontal>
            <StackItem>
              <h2>Sizes</h2>
            </StackItem>
            <StackItem align="center">
              <IconButton
                iconProps={{ iconName: 'Add' }}
                label="Add Size"
                onClick={() => {
                  createAdUnitSize()
                }}
              />
            </StackItem>
          </Stack>

          {details?.adUnit?.data?.attributes.adunit_sizes.data.map((size) => (
            <AdUnitSize key={size.id} size={size} />
          ))}
        </div>
      )}
      {!!adUnit && (
        <Stack>
          <StackItem>
            <h2>Partners</h2>
          </StackItem>
          <StackItem>
            <Stack horizontal>
              <StackItem align="center">
                <ComboBox
                  selectedKey={selectedPartner}
                  onChange={(e, item) => {
                    setSelectedPartner(item.key)
                  }}
                  options={fullPartnerList
                    ?.filter(
                      (p) =>
                        !partner_adunits
                          ?.map((pa) => pa.attributes.partner.data.id)
                          ?.includes(p.id)
                    )
                    .map((p) => {
                      return { key: p.id, text: p.attributes.name }
                    })}
                />
              </StackItem>
              <StackItem align="center">
                <IconButton
                  iconProps={{ iconName: 'Add' }}
                  label="Add Partner"
                  disabled={!selectedPartner}
                  onClick={() => {
                    createPartnerAdunit({
                      variables: {
                        adUnit: adUnit.id,
                        partner: selectedPartner,
                        date: new Date().toISOString(),
                      },
                    })
                  }}
                />
              </StackItem>
            </Stack>
          </StackItem>

          {!!partner_adunits &&
            partner_adunits?.map((pa) => {
              return (
                <StackItem key={pa.id}>
                  <PartnerAdunitEdit
                    partnerAdunit={pa}
                    partner={
                      fullPartnerList.filter(
                        (p) => p.id === pa.attributes.partner.data.id
                      )[0]
                    }
                  />
                </StackItem>
              )
            })}
        </Stack>
      )}
    </Panel>
  )
}

export default AdUnitPanel
